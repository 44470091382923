import { Title, Container, Main } from '../../components'
import styles from './styles.module.css'
import MetaTags from 'react-meta-tags'

const Technologies = () => {
  
  return <Main>
    <MetaTags>
      <title>О проекте</title>
      <meta name="description" content="Фудграм - Технологии" />
      <meta property="og:title" content="О проекте" />
    </MetaTags>
    
    <Container>
      <h1 className={styles.title}>Технологии</h1>
      <div className={styles.content}>
        <div>
          <p>
            Проект <b>«Фудграм»</b> создан с использованием современных технологий, обеспечивающих стабильную и безопасную работу платформы.
          </p>
          <h2 className={styles.subtitle}>Технологии, которые применены в этом проекте:</h2>
          <div className={styles.text}>
            <ul className={styles.textItem}>
              <li className={styles.textItem}>
                <h3>Backend:</h3>
              </li>
              <ul className={styles.textItem}>
                <li className={styles.textItem}>
                  <b>Python и Django:</b> В основе нашего проекта лежит Django — мощный и гибкий фреймворк для веб-разработки на языке Python, который обеспечивает высокую производительность и безопасность.
                </li>
                <li className={styles.textItem}>
                  <b>Django REST Framework:</b> Используется для создания API, что позволяет легко интегрировать и масштабировать функциональность нашего сайта.
                </li>
                <li className={styles.textItem}>
                  <b>PostgreSQL:</b> Надежная и производительная реляционная база данных для хранения данных пользователей и рецептов.
                </li>
              </ul>
              <li className={styles.textItem}>
                <h3>Дополнительные технологии:</h3>
              </li>
              <ul className={styles.textItem}>
                <li className={styles.textItem}>
                  <b>Docker:</b> Используется для контейнеризации приложения, что обеспечивает его легкую развертку и управление.
                </li>
                <li className={styles.textItem}>
                  <b>Nginx:</b> Веб-сервер для обработки запросов и балансировки нагрузки.
                </li>
                <li className={styles.textItem}>
                  <b>Gunicorn:</b> WSGI-сервер для запуска нашего Django-приложения в продакшн-среде.
                </li>
                <li className={styles.textItem}>
                  <b>CI/CD:</b> Интеграция и развертывание проекта автоматизированы с использованием современных инструментов CI/CD, что позволяет нам быстро вносить изменения и улучшения.
                </li>
              </ul>
            </ul>
            <p>
              Мы постоянно следим за развитием технологий и стремимся применять лучшие практики в разработке, чтобы обеспечить нашим пользователям лучший опыт использования «Фудграм».
            </p>
          </div>
        </div>
      </div>
      
    </Container>
  </Main>
}

export default Technologies

